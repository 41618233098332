small<template>
  <div>
    <b-card>
      <b-button-toolbar justify>
        <div>
          <b-button
            v-b-tooltip.hover="'Delete user'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon mb-1"
            @click="DeleteUser()"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-if="!UserLocked && (userid != currentid)"
            v-b-tooltip.hover="'Lock user'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon mb-1"
            @click="LockUser()"
          >
            <font-awesome-icon
              :icon="['fas', 'lock']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-if="UserLocked && (userid != currentid)"
            v-b-tooltip.hover="'Lock user'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon mb-1"
            @click="UnlockUser()"
          >
            <font-awesome-icon
              :icon="['fas', 'lock-open']"
              size="lg"
            />
          </b-button>&nbsp;
        </div>
        <div>
          <b-button
            v-b-tooltip.hover="'Save'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="SaveUser()"
          >
            <font-awesome-icon
              :icon="['far', 'save']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
      <b-row>
        <b-col md="12">
          <b-tabs>
            <b-tab
              ref="DetailTab"
              title="Detail"
            >
              <validation-observer
                ref="headerRules"
                tag="form"
              >
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="User Name"
                        rules="required|max:95|email"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>User Name:</b></i></label>
                          <b-form-input
                            id="UserName"
                            v-model="UserName"
                            type="email"
                            disabled
                            :state="errors.length > 0 ? false:null"
                            placeholder="User Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        rules="max:45"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>Password (Enter if change password):</b></i></label>
                          <b-form-input
                            id="Password"
                            v-model="Password"
                            type="password"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Password"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules=""
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>Email:</b></i></label>
                          <b-form-input
                            id="Email"
                            v-model="UserName"
                            type="email"
                            disabled
                            :state="errors.length > 0 ? false:null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Role"
                        rules="required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>Role:</b></i></label>
                          <v-select
                            id="RoleOption"
                            v-model="Role"
                            :options="RoleOptions"
                            label="text"
                            placeholder="Select the role"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="First Name"
                        rules="required|max:70"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>First Name:</b></i></label>
                          <b-form-input
                            id="FirstName"
                            v-model="FirstName"
                            :state="errors.length > 0 ? false:null"
                            placeholder="First Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Last Name"
                        rules="required|max:70"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>Last Name:</b></i></label>
                          <b-form-input
                            id="LastName"
                            v-model="LastName"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Phone Number"
                        rules="max:15"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>Phone #:</b></i></label>
                          <b-form-input
                            id="PhoneNumber"
                            v-model="PhoneNumber"
                            type="number"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Phone #"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div v-if="Claims.ABIES || Claims.ABIISF || Claims.ABIQuery || Claims.ABIParty || Claims.ABIStatement || Claims.ABISettings">
                  <div
                    class="divider my-2"
                  >
                    <div class="divider-text">
                      ABI Database Connection
                    </div>
                  </div>
                  <b-row>
                    <b-col md="5">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Host"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>Host:</b></i></label>
                            <b-form-input
                              id="ABIDbHost"
                              v-model="ABIDbHost"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Host"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Port"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>Port:</b></i></label>
                            <b-form-input
                              id="ABIDbPort"
                              v-model="ABIDbPort"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Port"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Database Name"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>Db Name:</b></i></label>
                            <b-form-input
                              id="ABIDbName"
                              v-model="ABIDbName"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Db Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="User Name"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>User Name:</b></i></label>
                            <b-form-input
                              id="ABIDbUserName"
                              v-model="ABIDbUserName"
                              :state="errors.length > 0 ? false:null"
                              placeholder="User Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Password"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>Password:</b></i></label>
                            <b-form-input
                              id="ABIDbPassword"
                              v-model="ABIDbPassword"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Password"
                              type="password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="Claims.Booking || Claims.BookingShipment || Claims.BookingSettings || Claims.BookingCustomer || Claims.BookingReports">
                  <div
                    class="divider my-2"
                  >
                    <div class="divider-text">
                      Booking Database Connection
                    </div>
                  </div>
                  <b-row>
                    <b-col md="5">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Host"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>Host:</b></i></label>
                            <b-form-input
                              id="BookingDbHost"
                              v-model="BookingDbHost"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Host"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Port"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>Port:</b></i></label>
                            <b-form-input
                              id="BookingDbPort"
                              v-model="BookingDbPort"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Port"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Database Name"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>Db Name:</b></i></label>
                            <b-form-input
                              id="BookingDbName"
                              v-model="BookingDbName"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Db Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="User Name"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>User Name:</b></i></label>
                            <b-form-input
                              id="BookingDbUserName"
                              v-model="BookingDbUserName"
                              :state="errors.length > 0 ? false:null"
                              placeholder="User Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Password"
                          rules=""
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label><i><b>Password:</b></i></label>
                            <b-form-input
                              id="BookingDbPassword"
                              v-model="BookingDbPassword"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Password"
                              type="password"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>

              </validation-observer>
            </b-tab>
            <b-tab
              ref="ModuleTab"
              title="Modules"
            >
              <div class="divider my-2">
                <div class="divider-text">
                  General
                </div>
              </div>
              <b-row>
                <b-col md="4">
                  <label><i><b>Public (Enabled for all user):</b></i></label>
                  <b-form-checkbox
                    v-model="Claims.Public"
                    disabled
                  />
                </b-col>
                <b-col md="4">
                  <label><i><b>Dashboard (Enabled for all user):</b></i></label>
                  <b-form-checkbox
                    v-model="Claims.Dashboard"
                    disabled
                  />
                </b-col>
              </b-row>
              <div v-if="Role.value === 'admin' || Role.value === 'readonly admin' || Role.value === 'company admin'">
                <div
                  class="divider my-2"
                >
                  <div class="divider-text">
                    Admin
                  </div>
                </div>
                <b-row>
                  <b-col md="4">
                    <label><i><b>Admin Page:</b></i></label>
                    <b-form-checkbox
                      v-model="Claims.Admin"
                    />
                  </b-col>
                  <b-col md="4">
                    <label><i><b>User Management:</b></i></label>
                    <b-form-checkbox
                      v-model="Claims.AdminUser"
                    />
                  </b-col>
                </b-row>
              </div>
              <div
                v-if="Module.ABIES || Module.ABIISF || Module.ABIQuery || Module.ABIParty || Module.ABIStatement || Module.ABISettings"
                class="divider my-2"
              >
                <div class="divider-text">
                  ABI System
                </div>
              </div>
              <b-row>
                <b-col
                  v-if="Module.ABIES"
                  md="4"
                >
                  <label><i><b>Entry Summary:</b></i></label>
                  <b-form-checkbox v-model="Claims.ABIES" />
                </b-col>
                <b-col
                  v-if="Module.ABIISF"
                  md="4"
                >
                  <label><i><b>ISF:</b></i></label>
                  <b-form-checkbox v-model="Claims.ABIISF" />
                </b-col>
                <b-col
                  v-if="Module.ABIParty"
                  md="4"
                >
                  <label><i><b>Party:</b></i></label>
                  <b-form-checkbox v-model="Claims.ABIParty" />
                </b-col>
                <b-col
                  v-if="Module.ABIStatement"
                  md="4"
                >
                  <label><i><b>Statement:</b></i></label>
                  <b-form-checkbox v-model="Claims.ABIStatement" />
                </b-col>
                <b-col
                  v-if="Module.ABISettings"
                  md="4"
                >
                  <label><i><b>Setting:</b></i></label>
                  <b-form-checkbox v-model="Claims.ABISettings" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="Module.ABIQuery"
                  md="4"
                >
                  <label><i><b>Query:</b></i></label>
                  <b-form-checkbox v-model="Claims.ABIQuery" />
                </b-col>
                <b-col
                  v-if="Module.ABIQuery"
                  md="8"
                >
                  <label><i><b><u>Query View:</u></b></i></label>
                  <v-select
                    id="QueryViewOptions"
                    v-model="Permission.ABI.QueryView"
                    :options="QueryViewOptions"
                    multiple
                    label="text"
                    placeholder="Select a view"
                  />
                </b-col>
              </b-row>
              <div
                v-if="Module.Booking"
                class="divider my-2"
              >
                <div class="divider-text">
                  Booking System
                </div>
              </div>
              <b-row>
                <b-col
                  v-if="Claims.Booking || Claims.BookingShipment"
                  md="12"
                >
                  <label><i><b><u>Allowed Customer View (Required for Users/Customers):</u></b></i></label>
                  <v-select
                    id="CustomerOption"
                    v-model="Permission.Booking.CustomerView"
                    :options="CustomerOptions"
                    multiple
                    label="name"
                    placeholder="Select a Customer"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="Module.Booking"
                  md="2"
                >
                  <label><i><b><u>Booking:</u></b></i></label>
                  <b-form-checkbox v-model="Claims.Booking" />
                </b-col>
                <b-col
                  v-if="Claims.Booking"
                  md="2"
                >
                  <label><i>Create Booking:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.CreateBooking" />
                </b-col>
                <b-col
                  v-if="Claims.Booking"
                  md="2"
                >
                  <label><i>Edit Booking:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.EditBooking" />
                </b-col>
                <b-col
                  v-if="Claims.Booking"
                  md="2"
                >
                  <label><i>Delete Booking:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.DeleteBooking" />
                </b-col>
                <b-col
                  v-if="Claims.Booking"
                  md="2"
                >
                  <label><i>Approve Booking:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.ApproveBooking" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="Module.Booking"
                  md="2"
                >
                  <label><i><b><u>Carrier:</u></b></i></label>
                  <b-form-checkbox v-model="Claims.BookingCarrier" />
                </b-col>
                <b-col
                  v-if="Claims.BookingCarrier"
                  md="2"
                >
                  <label><i>Create Carrier:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.CreateCarrier" />
                </b-col>
                <b-col
                  v-if="Claims.BookingCarrier"
                  md="2"
                >
                  <label><i>Edit Carrier:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.EditCarrier" />
                </b-col>
                <b-col
                  v-if="Claims.BookingCarrier"
                  md="2"
                >
                  <label><i>Delete Carrier:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.DeleteCarrier" />
                </b-col>
                <b-col
                  v-if="Claims.BookingCarrier"
                  md="2"
                >
                  <label><i>Edit Sailing Schedule:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.EditSailingSchedule" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="Module.BookingCustomer"
                  md="2"
                >
                  <label><i><b><u>Customer:</u></b></i></label>
                  <b-form-checkbox v-model="Claims.BookingCustomer" />
                </b-col>
                <b-col
                  v-if="Claims.BookingCustomer"
                  md="2"
                >
                  <label><i>Create Customer:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.CreateCustomer" />
                </b-col>
                <b-col
                  v-if="Claims.BookingCustomer"
                  md="2"
                >
                  <label><i>Edit Customer:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.EditCustomer" />
                </b-col>
                <b-col
                  v-if="Claims.BookingCustomer"
                  md="2"
                >
                  <label><i>Delete Customer:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.DeleteCustomer" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="Module.BookingShipment"
                  md="2"
                >
                  <label><i><b><u>Shipment:</u></b></i></label>
                  <b-form-checkbox v-model="Claims.BookingShipment" />
                </b-col>
                <b-col
                  v-if="Claims.BookingShipment"
                  md="2"
                >
                  <label><i>Create Shipment:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.CreateShipment" />
                </b-col>
                <b-col
                  v-if="Claims.BookingShipment"
                  md="2"
                >
                  <label><i>Edit Shipment:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.EditShipment" />
                </b-col>
                <b-col
                  v-if="Claims.BookingShipment"
                  md="2"
                >
                  <label><i>Delete Shipment:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.DeleteShipment" />
                </b-col>
                <b-col
                  v-if="Claims.BookingShipment"
                  md="2"
                >
                  <label><i>Approve Shipment:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.ApproveShipment" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="Module.BookingContainerTracking"
                  md="2"
                >
                  <label><i><b><u>Container Tracking:</u></b></i></label>
                  <b-form-checkbox v-model="Claims.BookingContainerTracking" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="Module.BookingSettings"
                  md="2"
                >
                  <label><i><b><u>Settings:</u></b></i></label>
                  <b-form-checkbox v-model="Claims.BookingSettings" />
                </b-col>
                <b-col
                  v-if="Claims.BookingSettings"
                  md="2"
                >
                  <label><i>Edit Settings:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.EditSetting" />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="Module.BookingReports"
                  md="2"
                >
                  <label><i><b><u>Reports:</u></b></i></label>
                  <b-form-checkbox v-model="Claims.BookingReports" />
                </b-col>
                <b-col
                  v-if="Claims.BookingReports"
                  md="2"
                >
                  <label><i>DSR Report:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.DSRReport" />
                </b-col>
                <b-col
                  v-if="Permission.Booking.DSRReport && Claims.BookingReports"
                  md="6"
                >
                  <label><i>DSR Report Options</i></label>
                  <v-select
                    id="DSRReportOptions"
                    v-model="Permission.Booking.DSRReportOptions"
                    multiple
                    :options="ReportDSROptions"
                    label="name"
                    placeholder="Select the Options"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="Claims.BookingReports"
                  md="2"
                  offset-md="2"
                >
                  <label><i>Container Status Report:</i></label>
                  <b-form-checkbox v-model="Permission.Booking.ContainerReport" />
                </b-col>
              </b-row>

              <div class="divider my-2">
                <div class="divider-text">
                  Miscellaneous
                </div>
              </div>
              <b-row>
                <b-col
                  v-if="Module.MiscOneStepUp"
                  md="2"
                >
                  <label><i><b><u>One Step Up:</u></b></i></label>
                  <b-form-checkbox v-model="Claims.MiscOneStepUp" />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  data() {
    return {
      required,
      currentid: this.$store.state.user.AppActiveUser.Id,
      userid: this.$route.params.userid,
      UserName: '',
      Email: '',
      Password: '',
      FirstName: '',
      LastName: '',
      PhoneNumber: '',
      Role: '',
      StationId: '',
      TwoFactorEnabled: false,
      ABIDbContext: '',
      ABIDbHost: '',
      ABIDbName: '',
      ABIDbPort: '',
      ABIDbUserName: '',
      ABIDbPassword: '',
      ACASDbContext: '',
      ACASDbHost: '',
      ACASDbName: '',
      ACASDbPort: '',
      ACASDbUserName: '',
      ACASDbPassword: '',
      AirAMSDbContext: '',
      AirAMSDbHost: '',
      AirAMSDbName: '',
      AirAMSDbPort: '',
      AirAMSDbUserName: '',
      AirAMSDbPassword: '',
      OceanAMSDbContext: '',
      OceanAMSDbHost: '',
      OceanAMSDbName: '',
      OceanAMSDbPort: '',
      OceanAMSDbUserName: '',
      OceanAMSDbPassword: '',
      TrackRailAMSDbContext: '',
      TrackRailAMSDbHost: '',
      TrackRailAMSDbName: '',
      TrackRailAMSDbPort: '',
      TrackRailAMSDbUserName: '',
      TrackRailAMSDbPassword: '',
      BookingDbContext: '',
      BookingDbHost: '',
      BookingDbName: '',
      BookingDbPort: '',
      BookingDbUserName: '',
      BookingDbPassword: '',
      UserLocked: false,
      QueryViewOptions: [
        { text: 'Air', value: 'A' },
        { text: 'Ocean/Rail/Truck', value: 'O' },
      ],
      RoleOptions: [
        { text: 'User', value: 'user' },
        { text: 'Manager', value: 'manager' },
        { text: 'Admin', value: 'company admin' },
        { text: 'Customer', value: 'customer' },
        { text: 'Readonly Admin', value: 'readonly admin' },
        { text: 'Readonly', value: 'read' },
      ],
      Claims: {
        Public: true,
        User: true,
        Dashboard: true,
        // ABI
        ABIES: false,
        ABIISF: false,
        ABIParty: false,
        ABIQuery: false,
        ABISettings: false,
        ABIStatement: false,
        // Booking
        Booking: false,
        BookingShipment: false,
        BookingSettings: false,
        BookingCustomer: false,
        BookingReports: false,
        BookingCarrier: false,
        BookingContainerTracking: false,
        // Admin
        Admin: false,
        AdminUser: false,
        // Misc
        MiscOneStepUp: false,
      },
      AllowedModule: [],
      Module: {
        public: true,
        dashboard: true,
        User: true,
        ABIES: false,
        ABIISF: false,
        ABIParty: false,
        ABIQuery: false,
        ABISettings: false,
        ABIStatement: false,
        Booking: false,
        MiscOneStepUp: false,
      },
      Permission: {
        ABI: {
          QueryView: [],
        },
        AirAMS: {},
        OceanAMS: {},
        TruckRailAMS: {},
        Booking: {
          CreateBooking: false,
          ApproveBooking: false,
          EditBooking: false,
          DeleteBooking: false,
          CustomerView: [],
          CreateCustomer: false,
          EditCustomer: false,
          DeleteCustomer: false,
          // Carrier
          CreateCarrier: false,
          EditCarrier: false,
          DeleteCarrier: false,
          EditSailingSchedule: false,
          // Shipment
          CreateShipment: false,
          EditShipment: false,
          DeleteShipment: false,
          ApproveShipment: false,
          EditSetting: false,
          // Reports
          ContainerReport: false,
          DSRReport: false,
          DSRReportOptions: [], // {text:, value: }
        },
        Misc: {
          OneStepUp: false,
        },
      },
      ReportDSROptions: [],
      CustomerOptions: [],
    }
  },
  beforeMount() {
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadAllowedModule(), this.LoadUser()])
        .then(() => {
          if (this.Claims.Booking) this.LoadBookingList()
          this.LoadingEnd()
        })
    },
    LoadAllowedModule() {
      return axios.get(`/identity/admin/${this.$store.state.user.AppActiveUser.ClientId}/modules/allowed`)
        .then(resp => {
          const response = resp.data
          this.AllowedModule = JSON.parse(response.allowedModule)
          this.AllowedModule.forEach(e => {
            switch (e.subject) {
              default:
                break
              case 'ABI-ES':
                this.Module.ABIES = true
                break
              case 'ABI-ISF':
                this.Module.ABIISF = true
                break
              case 'ABI-Party':
                this.Module.ABIParty = true
                break
              case 'ABI-Query':
                this.Module.ABIQuery = true
                break
              case 'ABI-Settings':
                this.Module.ABISettings = true
                break
              case 'ABI-Statement':
                this.Module.ABIStatement = true
                break
              case 'Booking':
                this.Module.Booking = true
                break
              case 'Booking-Customer':
                this.Module.BookingCustomer = true
                break
              case 'Booking-Shipment':
                this.Module.BookingShipment = true
                break
              case 'Booking-ContainerTracking':
                this.Module.BookingContainerTracking = true
                break
              case 'Booking-Settings':
                this.Module.BookingSettings = true
                break
              case 'Booking-Reports':
                this.Module.BookingReports = true
                break
              case 'Misc-OneStepUp':
                this.Module.MiscOneStepUp = true
                break
            }
          })
        })
    },
    LoadUser() {
      return axios.get(`/identity/admin/users/${this.$store.state.user.AppActiveUser.Id}/${this.userid}`)
        .then(resp => {
          const response = resp.data
          const respdata = response
          this.UserName = respdata.user.userName
          this.Email = respdata.user.email
          this.FirstName = respdata.user.firstName
          this.LastName = respdata.user.lastName
          this.PhoneNumber = respdata.user.phoneNumber
          this.Role = this.RoleOptions.find(option => option.value === respdata.user.role)
          this.StationId = respdata.user.stationID
          this.TwoFactorEnabled = respdata.user.twoFactorEnabled
          this.ABIDbContext = window.atob(respdata.user.abiDbContext)
          try {
            const [DbHostPort, DbName, DbUserName, DbPassword] = this.ABIDbContext.split(';')
            const [DbHost, DbPort] = DbHostPort.split(',')
            this.ABIDbHost = DbHost
            this.ABIDbName = DbName
            this.ABIDbPort = DbPort
            this.ABIDbUserName = DbUserName
            this.ABIDbPassword = DbPassword
          } catch (e) {
            this.ABIDbHost = ''
            this.ABIDbName = ''
            this.ABIDbPort = ''
            this.ABIDbUserName = ''
            this.ABIDbPassword = ''
          }
          this.ACASDbContext = window.atob(respdata.user.acasDbContext)
          try {
            const [DbHostPort, DbName, DbUserName, DbPassword] = this.ABIDbContext.split(';')
            const [DbHost, DbPort] = DbHostPort.split(',')
            this.ACASDbHost = DbHost
            this.ACASDbName = DbName
            this.ACASDbPort = DbPort
            this.ACASDbUserName = DbUserName
            this.ACASDbPassword = DbPassword
          } catch (e) {
            this.ACASDbHost = ''
            this.ACASDbName = ''
            this.ACASDbPort = ''
            this.ACASDbUserName = ''
            this.ACASDbPassword = ''
          }
          this.AirAMSDbContext = window.atob(respdata.user.airAMSDbContext)
          try {
            const [DbHostPort, DbName, DbUserName, DbPassword] = this.ABIDbContext.split(';')
            const [DbHost, DbPort] = DbHostPort.split(',')
            this.AirAMSDbHost = DbHost
            this.AirAMSDbName = DbName
            this.AirAMSDbPort = DbPort
            this.AirAMSDbUserName = DbUserName
            this.AirAMSDbPassword = DbPassword
          } catch (e) {
            this.AirAMSDbHost = ''
            this.AirAMSDbName = ''
            this.AirAMSDbPort = ''
            this.AirAMSDbUserName = ''
            this.AirAMSDbPassword = ''
          }
          this.OceanAMSDbContext = window.atob(respdata.user.oceanAMSDbContext)
          try {
            const [DbHostPort, DbName, DbUserName, DbPassword] = this.ABIDbContext.split(';')
            const [DbHost, DbPort] = DbHostPort.split(',')
            this.OceanAMSDbHost = DbHost
            this.OceanAMSDbName = DbName
            this.OceanAMSDbPort = DbPort
            this.OceanAMSDbUserName = DbUserName
            this.OceanAMSDbPassword = DbPassword
          } catch (e) {
            this.OceanAMSDbHost = ''
            this.OceanAMSDbName = ''
            this.OceanAMSDbPort = ''
            this.OceanAMSDbUserName = ''
            this.OceanAMSDbPassword = ''
          }
          this.TrackRailAMSDbContext = window.atob(respdata.user.truckRailAMSDbContext)
          try {
            const [DbHostPort, DbName, DbUserName, DbPassword] = this.ABIDbContext.split(';')
            const [DbHost, DbPort] = DbHostPort.split(',')
            this.TrackRailAMSDbHost = DbHost
            this.TrackRailAMSDbName = DbName
            this.TrackRailAMSDbPort = DbPort
            this.TrackRailAMSDbUserName = DbUserName
            this.TrackRailAMSDbPassword = DbPassword
          } catch (e) {
            this.TrackRailAMSDbHost = ''
            this.TrackRailAMSDbName = ''
            this.TrackRailAMSDbPort = ''
            this.TrackRailAMSDbUserName = ''
            this.TrackRailAMSDbPassword = ''
          }
          this.BookingDbContext = window.atob(respdata.user.bookingDbContext)
          try {
            const [DbHostPort, DbName, DbUserName, DbPassword] = this.BookingDbContext.split(';')
            const [DbHost, DbPort] = DbHostPort.split(',')
            this.BookingDbHost = DbHost
            this.BookingDbName = DbName
            this.BookingDbPort = DbPort
            this.BookingDbUserName = DbUserName
            this.BookingDbPassword = DbPassword
          } catch (e) {
            this.BookingDbHost = ''
            this.BookingDbName = ''
            this.BookingDbPort = ''
            this.BookingDbUserName = ''
            this.BookingDbPassword = ''
          }
          this.UserLocked = !!(respdata.user.lockoutEnd != null && respdata.user.lockoutEnd !== '')
          try {
            if (Date.parse(respdata.user.lockoutEnd) > Date.now()) this.UserLocked = true
            else { this.UserLocked = false }
          } catch (e) {
            this.UserLocked = false
          }
          const Claims = respdata.claims
          Claims.forEach(e => {
            switch (e.type) {
              default:
                break
              case 'Permission':
                try {
                  const permissionlist = JSON.parse(e.value)
                  this.Permission.AirAMS = permissionlist.AirAMS
                  this.Permission.OceanAMS = permissionlist.OceanAMS
                  this.Permission.TruckRailAMS = permissionlist.TruckRailAMS
                  this.Permission.ABI = permissionlist.ABI
                  this.Permission.Booking = permissionlist.Booking
                  this.Permission.Misc = permissionlist.Misc
                } catch (error) {
                  this.Permission = {
                    ABI: {},
                    AirAMS: {},
                    OceanAMS: {},
                    TruckRailAMS: {},
                    Booking: {
                      CreateBooking: false,
                      ApproveBooking: false,
                      EditBooking: false,
                      DeleteBooking: false,
                      CustomerView: [],
                      CreateCustomer: false,
                      EditCustomer: false,
                      DeleteCustomer: false,
                      CreateShipment: false,
                      EditShipment: false,
                      DeleteShipment: false,
                      EditSetting: false,
                      ContainerReport: false,
                      DSRReport: false,
                      DSRReportOptions: [],
                    },
                    Misc: {
                      OneStepUp: false,
                    },
                  }
                }

                break
              case 'Module':
                // eslint-disable-next-line no-case-declarations
                const modulelist = JSON.parse(e.value)
                modulelist.forEach(c => {
                  switch (c.subject) {
                    default:
                      break
                    case 'Admin':
                      this.Claims.Admin = true
                      break
                    case 'Admin-User':
                      this.Claims.AdminUser = true
                      break
                    case 'EDI':
                      this.Claims.EDI = true
                      break
                    case 'ABI-ES':
                      this.Claims.ABIES = true
                      break
                    case 'ABI-ISF':
                      this.Claims.ABIISF = true
                      break
                    case 'ABI-Party':
                      this.Claims.ABIParty = true
                      break
                    case 'ABI-Query':
                      this.Claims.ABIQuery = true
                      break
                    case 'ABI-Settings':
                      this.Claims.ABISettings = true
                      break
                    case 'ABI-Statement':
                      this.Claims.ABIStatement = true
                      break
                    case 'Booking':
                      this.Claims.Booking = true
                      break
                    case 'Booking-Customer':
                      this.Claims.BookingCustomer = true
                      break
                    case 'Booking-Shipment':
                      this.Claims.BookingShipment = true
                      break
                    case 'Booking-ContainerTracking':
                      this.Claims.BookingContainerTracking = true
                      break
                    case 'Booking-Settings':
                      this.Claims.BookingSettings = true
                      break
                    case 'Booking-Reports':
                      this.Claims.BookingReports = true
                      break
                    case 'Booking-Carrier':
                      this.Claims.BookingCarrier = true
                      break
                    case 'Misc-OneStepUp':
                      this.Claims.MiscOneStepUp = true
                      break
                  }
                })
                break
            }
          })
        })
    },
    SaveUser() {
      this.$refs.headerRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ModuleClaim = [
            {
              action: 'read',
              subject: 'public',
            },
            {
              action: 'read',
              subject: 'dashboard',
            },
            {
              action: 'read',
              subject: 'User',
            }]
          const ModuleSearchListClaim = [{
            title: 'Dashboard',
            route: { name: 'Dashboard' },
            icontype: 'fas',
            icon: 'home',
            isBookmarked: true,
          },
          // {
          //   title: 'ABI User Modules',
          //   url: '/abi/settings/users',
          //   route: { name: 'Booking-Settings' },
          //   icon: 'SettingsIcon',
          //   isBookmarked: false,
          // },
          ]

          // Admin
          if (this.Claims.Admin) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Admin',
            })
          }
          if (this.Claims.AdminUser) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Admin-User',
            })
          }
          // ABI
          if (this.Claims.ABIES) {
            ModuleClaim.push({
              action: 'read',
              subject: 'ABI-ES',
            })
            ModuleSearchListClaim.push({
              title: 'Entry Summary',
              route: { name: 'ABI-ES' },
              icontype: 'fas',
              icon: 'file-invoice',
              isBookmarked: false,
            })
          }
          if (this.Claims.ABIISF) {
            ModuleClaim.push({
              action: 'read',
              subject: 'ABI-ISF',
            })
            ModuleSearchListClaim.push({
              title: 'ISF',
              route: { name: 'ABI-ISF' },
              icontype: 'fas',
              icon: 'ship',
              isBookmarked: false,
            })
          }
          if (this.Claims.ABIParty) {
            ModuleClaim.push({
              action: 'read',
              subject: 'ABI-Party',
            })
            ModuleSearchListClaim.push({
              title: 'Customers',
              route: { name: 'ABI-Customers' },
              icontype: 'fas',
              icon: 'user-tie',
              isBookmarked: false,
            },
            {
              title: 'Parties',
              route: { name: 'ABI-Parties' },
              icontype: 'fas',
              icon: 'user-tie',
              isBookmarked: false,
            })
          }
          if (this.Claims.ABIQuery) {
            ModuleClaim.push({
              action: 'read',
              subject: 'ABI-Query',
            })
            ModuleSearchListClaim.push({
            //   title: 'HTS',
            //   route: { name: 'ABI-HTS' },
            //   icontype: 'fas',
            //   icon: 'book',
            //   isBookmarked: false,
            // },
            // {
              title: 'AD/CVD Query',
              route: { name: 'ABI-Query-ADCVD' },
              icontype: 'far',
              icon: 'question-circle',
              isBookmarked: false,
            },
            {
              title: 'Cargo Status Query',
              route: { name: 'ABI-Query-CargoStatus' },
              icontype: 'far',
              icon: 'question-circle',
              isBookmarked: false,
            },
            // {
            //   title: 'Entry Summary Query',
            //   route: { name: 'ABI-Query-ES' },
            //   icontype: 'far',
            //   icon: 'question-circle',
            //   isBookmarked: false,
            // },
            // {
            //   title: 'Importer/Bond Query',
            //   url: '/abi/query/importer',
            //   route: { name: 'ABI-Query-Importer' },
            //   icontype: 'far',
            //   icon: 'question-circle',
            //   isBookmarked: false,
            // },
            {
              title: 'Manufacturer(MID) Query',
              route: { name: 'ABI-Query-MID' },
              icontype: 'far',
              icon: 'question-circle',
              isBookmarked: false,
            })
          }

          if (this.Claims.ABISettings) {
            ModuleClaim.push({
              action: 'read',
              subject: 'ABI-Settings',
            })
            ModuleSearchListClaim.push({
              title: 'Queue Setup',
              route: { name: 'ABI-Settings-Queue' },
              icontype: 'fas',
              icon: 'cogs',
              isBookmarked: false,
            },
            {
              title: 'Module Setup',
              route: { name: 'ABI-settings-Modules' },
              icontype: 'fas',
              icon: 'cogs',
              isBookmarked: false,
            })
          }

          if (this.Claims.ABIStatement) {
            ModuleClaim.push({
              action: 'read',
              subject: 'ABI-Statement',
            })
            ModuleSearchListClaim.push({
              title: 'Daily Statement',
              route: { name: 'ABI-Statement-Daily' },
              icontype: 'fas',
              icon: 'file-invoice-dollar',
              isBookmarked: false,
            },
            {
              title: 'Monthly Statement',
              route: { name: 'ABI-Statement-Monthly' },
              icontype: 'fas',
              icon: 'file-invoice-dollar',
              isBookmarked: false,
            })
          }

          // Booking
          if (this.Claims.Booking) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Booking',
            })
            ModuleSearchListClaim.push({
              title: 'Booking',
              route: { name: 'Booking' },
              icontype: 'fas',
              icon: 'file-invoice',
              isBookmarked: false,
            },
            {
              title: 'Booking Archive',
              route: { name: 'Booking-Archive' },
              icontype: 'fas',
              icon: 'archive',
              isBookmarked: false,
            })
          }

          if (this.Claims.BookingShipment) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Booking-Shipment',
            })
            ModuleSearchListClaim.push({
              title: 'Shipments',
              route: { name: 'Booking-Shipment' },
              icontype: 'fas',
              icon: 'shipping-fast',
              isBookmarked: false,
            },
            {
              title: 'Shipment Archive',
              route: { name: 'Booking-Shipments-Archive' },
              icontype: 'fas',
              icon: 'archive',
              isBookmarked: false,
            })
          }

          if (this.Claims.BookingContainerTracking) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Booking-ContainerTracking',
            })
            ModuleSearchListClaim.push({
              title: 'Container Tracking',
              route: { name: 'Booking-ContainerTracking' },
              icontype: 'fas',
              icon: 'boxes',
              isBookmarked: false,
            })
          }

          if (this.Claims.BookingCustomer) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Booking-Customer',
            })
            ModuleSearchListClaim.push({
              title: 'Customers',
              route: { name: 'Booking-Customer' },
              icontype: 'fas',
              icon: 'user-tie',
              isBookmarked: false,
            })
          }

          if (this.Claims.BookingCarrier) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Booking-Carrier',
            })
            ModuleSearchListClaim.push({
              title: 'Customers',
              route: { name: 'Booking-Carrier' },
              icontype: 'fas',
              icon: 'truck-loading',
              isBookmarked: false,
            })
          }

          if (this.Claims.BookingSettings) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Booking-Settings',
            })
            ModuleSearchListClaim.push({
              title: 'Booking Settings',
              route: { name: 'Booking-Settings' },
              icontype: 'fas',
              icon: 'cogs',
              isBookmarked: false,
            })
          }

          if (this.Claims.BookingReports) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Booking-Reports',
            })
          }

          if (this.Permission.Booking.ContainerReport) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Booking-Reports-ContainerStatus',
            })
          }

          if (this.Permission.Booking.DSRReport) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Booking-Reports-DSR',
            })
          }

          // Misc
          if (this.Claims.MiscOneStepUp) {
            ModuleClaim.push({
              action: 'read',
              subject: 'Misc-OneStepUp',
            })
            ModuleSearchListClaim.push({
              title: 'One Step Up Active Containers',
              route: { name: 'Misc-OneStepUp-Active' },
              icontype: 'fas',
              icon: 'file-invoice',
              isBookmarked: false,
            })
            ModuleSearchListClaim.push({
              title: 'One Step Up Archived Containers',
              route: { name: 'Misc-OneStepUp-Archive' },
              icontype: 'fas',
              icon: 'file-invoice',
              isBookmarked: false,
            })
          }

          const UserDto = {
            UserId: this.$store.state.user.AppActiveUser.Id,
            UserLookupId: this.userid,
            UserPassword: this.Password,
          }
          const IdentityModel = {
            User: {
              Role: this.Role.value,
              FirstName: this.FirstName,
              LastName: this.LastName,
              Email: this.Email,
              StationId: this.StationId,
              TwoFactorEnabled: false,
              ClientId: this.$store.state.user.AppActiveUser.ClientId,
              UserName: this.Email,
              PhoneNumber: this.PhoneNumber,
              ABIDbContext: (this.Claims.ABIES || this.Claims.ABIISF || this.Claims.ABIQuery || this.Claims.ABIParty || this.Claims.ABIStatement || this.Claims.ABISettings) ? window.btoa(`${this.ABIDbHost},${this.ABIDbPort};${this.ABIDbName};${this.ABIDbUserName};${this.ABIDbPassword}`) : window.btoa(''),
              ACASDbContext: '',
              ACASDbHost: '',
              AirAMSDbContext: '',
              OceanAMSDbContext: '',
              TruckRailAMSDbContext: '',
              BookingDbContext: (this.Claims.Booking) ? window.btoa(`${this.BookingDbHost},${this.BookingDbPort};${this.BookingDbName};${this.BookingDbUserName};${this.BookingDbPassword}`) : window.btoa(''),
            },
            Roles: [
              this.Role.value,
            ],
            ClaimsUpdate: [
              {
                type: 'Permission',
                value: JSON.stringify(this.Permission),
              },
              {
                type: 'Module',
                value: JSON.stringify(ModuleClaim),
              },
              {
                type: 'ModuleSearchList',
                value: JSON.stringify({
                  pages: {
                    key: 'title',
                    data: ModuleSearchListClaim,
                  },
                }),
              },
            ],
          }
          axios.put('/identity/admin/users', {
            UserDto,
            IdentityModel,

          })
            .then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('User data has been saved.')
            })
            .catch(e => {
              this.LoadingEnd()
              this.DisplayError('User cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('User cannot be saved', 'Please check data requirements.')
        }
      })
    },
    DeleteUser() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete('/identity/admin/users', {
            data: {
              UserId: this.$store.state.user.AppActiveUser.Id,
              UserLookupId: this.userid,
            },
          }).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('User has been removed.')
              .then(() => {
                // redirect
                this.$router.push('/admin/userlist')
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove user.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    LockUser() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, lock it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          const UserDto = {
            UserId: this.$store.state.user.AppActiveUser.Id,
            UserLookupId: this.userid,
          }
          axios.put('/identity/admin/users/lock', {
            UserDto,
          }).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('User has been locked.')
              .then(() => {
                this.LoadAll()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot lock user.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    UnlockUser() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, unlock it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          const UserDto = {
            UserId: this.$store.state.user.AppActiveUser.Id,
            UserLookupId: this.userid,
          }

          axios.put('/identity/admin/users/unlock', {
            UserDto,
          }).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('User has been unlocked.')
              .then(() => {
                this.LoadAll()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot unlock user.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    LoadBookingList() {
      // Loading Customer List
      axios.defaults.headers.common.DBContext = window.btoa(this.BookingDbContext)
      return axios.get('/booking/customer/codes/all')
        .then(rowData => {
          this.CustomerOptions = rowData.data
          this.ReportDSROptions = rowData.data
        })
    },
  },
}
</script>
